import luisPrimary from '../ta-headshots/luisPrimary.jpeg';
import luisSecondary from '../ta-headshots/luisSecondary.jpeg';
import danielaPrimary from '../ta-headshots/danielaPrimary.png';
import danielaSecondary from '../ta-headshots/danielaSecondary.jpeg';
import minhajPrimary from '../ta-headshots/minhajPrimary.jpeg';
import minhajSecondary from '../ta-headshots/minhajSecondary.jpeg';
import bahaaPrimary from '../ta-headshots/bahaaPrimary.png';
import bahaaSecondary from '../ta-headshots/bahaaSecondary.jpeg';
import abolajiPrimary from '../ta-headshots/abolajiPrimary.jpeg';
import abolajiSecondary from '../ta-headshots/abolajiSecondary.jpeg';
// import dashaPrimary from '../headshots/dashaPrimary.png';
// import dashaSecondary from '../headshots/dashaSecondary.jpg';
import simonePrimary from '../ta-headshots/simonePrimary.jpeg';
import simoneSecondary from '../ta-headshots/simoneSecondary.jpeg';



export const cards = [
    {
        id: 1,
        title: 'CS 1110',
        image: luisPrimary,
        name: 'Luis Hernandez Rocha',
        secondaryImage: luisSecondary,
        majors: 'Computer Science and Math \'26',
        // insta: 'https://www.instagram.com/kaylaspray/',
        // linkedIn: 'https://www.linkedin.com/in/kaylasprayberry/',
        askAbout: ['CS 1110'],
        bio: "Hi everyone ! I am an international student from Managua, Nicaragua. I am currently interested in the intersection of Computer Science and Mathematics. In my free time, I like listening to Arctic Monkeys and Bad Bunny.",
        popup: true
    },
    {
        id: 2,
        title: 'CS 1110',
        image: danielaPrimary,
        name: 'Daniela Carmona Lopez Mestre',
        secondaryImage: danielaSecondary,
        majors: 'Computer Science | Business Minor \'25',
        insta: 'https://www.instagram.com/daniela_c.77/',
        linkedIn: 'https://www.linkedin.com/in/daniela-carmona-lopez-mestre/',
        askAbout: ['CS 4820'],
        bio: "Hi! I’m a junior studying Computer Science in the College of Engineering with a minor in Business. I’m a lead TA for CS 1110, I play in the Cornell women’s rugby team, and am a member of URMC. ",
        popup: true
    },
    {
        id: 3,
        title: 'CS 2110',
        image: minhajPrimary,
        name: 'Minhaj Fahad',
        secondaryImage: minhajSecondary,
        majors: 'Computer Science \'26',
        insta: 'https://www.instagram.com/minhaj.fahad/',
        linkedIn: 'https://www.linkedin.com/in/minhajfahad/',
        askAbout: ['CS 2110'],
        bio: "Hi, I’m Minhaj and I’m from Brooklyn, NY. I’m a sophomore in Engineering studying Computer Science. I love learning about software development, data science, and machine learning. Outside of class and work, I like to make movie reviews on IMBD, read, and hang out with my friends!",
        popup: true
    },
    {
        id: 4,
        title: 'CS 2800',
        image: bahaaPrimary,
        name: 'Bahaa Kotb',
        secondaryImage: bahaaSecondary,
        majors: 'Computer Science and ECE \'25',
        insta: 'https://www.instagram.com/bahaa.my/',
        linkedIn: 'https://www.linkedin.com/in/bahaa-kotb/',
        askAbout: ['CS 2800'],
        bio: "Hi everyone, my name is Bahaa! I'm currently a Junior in the College of Engineering pursuing a BS in Computer Science! I'm currently the CS lead on Cornell Cup Robotics and do research in Robotics and Machine Learning.  Outside of school you can find me gaming, playing soccer. hitting the gym or hanging out with friends!",
        popup: true
    },
    {
        id: 5,
        title: 'INFO 1300',
        image: abolajiPrimary,
        name: 'Abolaji Awoyomi',
        secondaryImage: abolajiSecondary,
        majors: 'Information Science | UX Concentration \'26',
        insta: 'https://www.instagram.com/bunjeea/',
        linkedIn: 'https://www.linkedin.com/in/abolaji-awoyomi/',
        askAbout: ['INFO 1300'],
        bio: "Hey! I'm Nigerian-American, born in Brooklyn, NY, but currently live in Long Island. I'm studying Information Science(UX Design) at CALS. I love meeting new people, so feel free to hit me up!",
        popup: true
    },
    // {
    //     id: 6,
    //     title: 'CS 3410',
    //     image: dashaPrimary,
    //     name: 'Dasha Griffiths',
    //     secondaryImage: dashaSecondary,
    //     majors: 'Computer Science \'24',
    //     insta: 'https://www.instagram.com/kaysuppp/',
    //     linkedIn: 'https://www.linkedin.com/in/dashagriffiths/',
    //     askAbout: ['CS 3410'],
    //     bio: "Hey, I'm Dasha, a senior CS student in the college of Arts and Sciences. I have been on the Eboard since I stepped on campus and love it for the community it opened to me and for the wonderful people I get to meet day by day. I am very happy to serve my community as social chair❤️ Hope to see you around!",
    //     popup: true
    // },
    {
        id: 7,
        title: 'CS 3410',
        image: simonePrimary,
        name: 'Simone Green',
        secondaryImage: simoneSecondary,
        majors: 'Computer Science | Information Science & Law Minors \'24',
        linkedIn: 'www.linkedin.com/in/green-simone/',
        askAbout: ['CS 3410'],
        bio: "Hi! My name is Simone and I'm a senior from Delaware studying CS in the College of Engineering. I'm super passionate about the intersection of geopolitics, policy, and technology, and am incredibly excited about the field of cybersecurity. I love to sing, hike, bake, and eat baked goods. I used to be on URMC eboard and have since stepped away, but I'm also involved with Cru, Theta Tau, and Alpha Phi Omega. This is my second year as a TA for 3410 and previously, I taught an AEW for CS 1110 for two years.         ",
        popup: true
    },



];